export const firebaseConfig = {
  apiKey: "AIzaSyC53jTe0qmEkFv9_fMVtOON0IHUdHezIns",
  authDomain: "fewchore-mobile.firebaseapp.com",
  projectId: "fewchore-mobile",
  storageBucket: "fewchore-mobile.appspot.com",
  messagingSenderId: "1088861750119",
  appId: "1:1088861750119:web:b51cc5f37af3bb150125c2",
  measurementId: "G-F2JREB1PNN",
};

export const fcmVapidKey =
  "BHhiX6SduXnlylBWLdgokix8UCFBhZLBC_7ve5UH7PJ1YzHf5buf7nPi6F6qHrbuEybq4uY8OYbKo20Ix93W_NM";
