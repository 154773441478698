import React, { useState } from "react";
import useSWRMutation from "swr/mutation";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  DelayedActionButton,
  OutlineButton,
  PrimaryButton,
} from "../../../components/Button";
import AccountHolderBox from "../../../components/AccountHolderBox";
import LoanRepaymentForm from "./LoanRepaymentForm";
import ActionForm from "./ActionForm";
import Modal from "../../../components/Modal";
import SendMessage from "../../../components/SendMessage";
import CardDropDown from "./CardDropDown";
import LoanInfo from "./LoanInfo";
import DisbursementModal from "./DisbursementModal";
import RepaymentModal from "./RepaymentModal";
import StatementAnalysis from "./StatementAnalysis";
import StatementTransactions from "./StatementTransactions";
import LoanOffers from "./LoanOffers";
import SuccessIcon from "../../../assets/icons/successicon";

import {
  getPermissions,
  sendPatchRequest,
  sendPostRequest,
} from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import GuarantorInfoDisplay from "./GuarantorInfo";
import LoanRepaymentScheduleDisplay from "./LoanRepaymentSchedule";
import CheckBox from "../../../components/Checkbox";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const LoanTypePayday = "payday-loan",
  LoanTypeAssetFinancing = "asset-financing-loan";

const VIEW_STATEMENT_KEY = "view-loan-statement";
const LOAN_REPAYMENT_KEY = "add-loan-repayment";
const CLOSE_LOAN_KEY = "close-loan";
const UPDATE_LOAN_KEY = "update-loans";
const DISBURSE_LOAN_KEY = "disburse-loan";
const STOP_LOAN_KEY = "stop-loan-interest";
const UPDATE_CLOSE_LOAN_KEY = "update-close-loan";
const UPDATE_STOP_LOAN_KEY = "update-stop-loan-interest";
const VIEW_SUMMARY_KEY = "dashboard-summary";

const LoanRepaymentFormID = 1,
  StopLoanFormID = 2,
  CloseLoanFormID = 3,
  DeclineLoanFormID = 4;

function LoanDetailsModal({
  loan,
  dismissModal,
  mutate,
  loanType,
  activeFilter,
  activeStatus,
  partnerID,
}) {
  const permissions = getPermissions();
  const isPaydayLoan = loanType === LoanTypePayday;
  const isAssetFinancingLoan = loanType === LoanTypeAssetFinancing;

  const canViewStatement = permissions[VIEW_STATEMENT_KEY];
  const canAddRepayment = permissions[LOAN_REPAYMENT_KEY];
  const canCloseLoan = permissions[CLOSE_LOAN_KEY];
  const canUpdateLoan = permissions[UPDATE_LOAN_KEY];
  const canDisburseLoan = permissions[DISBURSE_LOAN_KEY];
  const canStopLoan = permissions[STOP_LOAN_KEY];
  const canUpdateCloseLoan = permissions[UPDATE_CLOSE_LOAN_KEY];
  const canUpdateStopLoan = permissions[UPDATE_STOP_LOAN_KEY];
  const isFewchoreAdmin = permissions[VIEW_SUMMARY_KEY];
  const [requestedFormID, setRequestedFormID] = useState(null);
  const [salaryDate, setSalaryDate] = useState(null);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [disbursementInfoModal, setDisbursementInfoModal] = useState(false);
  const [repaymentInfoModal, setRepaymentInfoModal] = useState(false);
  const [isOverrideDisbursementChecked, setIsOverrideDisbursementChecked] =
    useState(false);

  const {
    state,
    data: loanFormData,
    setData,
    submitStarted,
    submitError,
    submitSuccess,
  } = useForm(loan);
  const showMessageModal = useMessageModal();

  const isPending = loan.status.startsWith("Pending");
  const isPendingDisbursement = isPending && loan.approvedBy;
  const isDeclined = loan.status === "Rejected";
  const isActive = loan.status === "Active";
  const isCompleted = loan.status === "Completed";

  const { trigger: stopInterestTrigger } = useSWRMutation(
    isFewchoreAdmin && partnerID
      ? `/loan/stop-interest?partnerID=${partnerID ?? ""}`
      : `/loan/stop-interest`,
    sendPatchRequest
  );

  const { trigger: closeLoanTrigger } = useSWRMutation(
    isFewchoreAdmin && partnerID
      ? `/loan/close?partnerID=${partnerID ?? ""}`
      : `/loan/close`,
    sendPatchRequest
  );

  const { trigger: updateTrigger } = useSWRMutation(
    isFewchoreAdmin && partnerID
      ? `/loan/update?partnerID=${partnerID ?? ""}`
      : `/loan/update`,
    sendPatchRequest
  );

  const { trigger: disburseTrigger } = useSWRMutation(
    isFewchoreAdmin && partnerID
      ? `/loan/disburse?partnerID=${partnerID ?? ""}`
      : `/loan/disburse`,
    sendPostRequest
  );

  const { trigger: statementTrigger } = useSWRMutation(
    `/loan/statement?loanID=${loan.loanID}&customerID=${loan.customerID}`
  );

  const [loanStatement, setLoanStatement] = useState(null);
  const fetchingLoanStatement =
    loanStatement && loanStatement.statementFetching;
  const fetchingLoanTransaction =
    loanStatement && loanStatement.transactionFetching;
  const hasPendingStopInterestRequest =
    loan.stopInterestRequest && !loan.stopInterestRequest.approvalTimestamp;

  const fetchLoanStatement = async () => {
    try {
      const data = await statementTrigger();
      return data;
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.response?.data ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      return null;
    }
  };

  const onStatementTransactionsClick = async () => {
    if (!canViewStatement) return;

    if (loanStatement)
      return setLoanStatement({
        ...loanStatement,
        statementDisplay: false,
        transactionDisplay: true,
      });

    setLoanStatement({ transactionFetching: true });

    const data = await fetchLoanStatement();

    if (!data) return setLoanStatement(null);

    setLoanStatement({
      data,
      statementDisplay: false,
      transactionDisplay: true,
    });
  };

  const onLoanStatementClick = async () => {
    if (!canViewStatement) return;

    if (loanStatement)
      return setLoanStatement({
        ...loanStatement,
        statementDisplay: true,
        transactionDisplay: false,
      });

    setLoanStatement({ statementFetching: true });

    const data = await fetchLoanStatement();

    if (!data) return setLoanStatement(null);

    setLoanStatement({
      data,
      statementDisplay: true,
      transactionDisplay: false,
    });
  };

  const hideLoanStatement = () => {
    setLoanStatement({
      ...loanStatement,
      statementDisplay: false,
      transactionDisplay: false,
    });
  };

  const hideMessageModal = () => {
    setSendMessageModal(false);
  };

  const showDisbursementModal = () => {
    setDisbursementInfoModal(true);
  };

  const hideDisbursementModal = () => {
    setDisbursementInfoModal(false);
  };

  const showRepaymentModal = () => {
    setRepaymentInfoModal(true);
  };

  const hideRepaymentModal = () => {
    setRepaymentInfoModal(false);
  };

  const onDateChange = ({ target }) => {
    setSalaryDate(target.value);
  };

  const approveCloseRequest = async () => {
    if (!canUpdateCloseLoan) return;
    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      approve: true,
    };

    try {
      const res = await closeLoanTrigger({
        payload: data,
      });
      showMessageModal({
        title: "Approved",
        message: res.data?.message || "Successfully approved",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  const approveStopInterest = async () => {
    if (!canUpdateStopLoan) return;
    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      approve: true,
    };

    try {
      const res = await stopInterestTrigger({
        payload: data,
      });
      showMessageModal({
        title: "Approved",
        message: res.data?.message || "Successfully approved",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  const approveLoan = async () => {
    if (!canUpdateLoan) return;
    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      Approve: true,
      ...(isPaydayLoan && {
        salaryDate: salaryDate
          ? dayjs(salaryDate, "YYYY-MM-DD").format("DD-MM-YYYY")
          : loan.salary.PaymentDate,
      }),
      ...(isAssetFinancingLoan && {
        loanOffers: loanFormData?.loanOffers,
      }),
    };

    try {
      const res = await updateTrigger({
        payload: data,
      });
      showMessageModal({
        title: "Approved",
        message: res.data?.message || "Successfully approved",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  const disburseLoan = async () => {
    if (!canDisburseLoan) return;
    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      ignorePreviousFailure: isOverrideDisbursementChecked,
      ...(isAssetFinancingLoan && {
        loanOffers: loanFormData?.loanOffers,
      }),
    };

    try {
      const res = await disburseTrigger(data);
      showMessageModal({
        title: "Disbursed",
        message: res.data?.message || "Successfully disbursed",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  const { loanTypeInfo, loanInfoElem, adminInfoElem } = LoanInfo({
    loan,
    loanType,
    salaryDate,
    activeFilter,
    activeStatus,
    canViewStatement,
    fetchingLoanStatement,
    fetchingLoanTransaction,
    onDateChange,
    showRepaymentModal,
    onLoanStatementClick,
    showDisbursementModal,
    onStatementTransactionsClick,
  });

  const guarantorInfo = loan.guarantorInfo;
  const repaymentSchedule = loan.repaymentSchedule ?? [];
  const amountPaid = loan.totalRepayment ?? 0;
  const showCards = loan.bankCards?.map((card, i) => (
    <CardDropDown {...card} key={i} index={i} length={loan.bankCards?.length} />
  ));

  const createActionButtons = () => {
    if (isDeclined || isCompleted) return null;
    if (isPending)
      return (
        <div className="flex col gap-8">
          {canUpdateLoan && (
            <OutlineButton
              onClick={() => setRequestedFormID(DeclineLoanFormID)}
              className="danger"
            >
              Decline
            </OutlineButton>
          )}
          {isPendingDisbursement ? (
            <>
              {canDisburseLoan && (
                <>
                  <DelayedActionButton
                    className="btn outline"
                    text="Disburse"
                    confirmText="Tap again to confirm"
                    busyText="Disbursing"
                    isBusy={state.submitting}
                    clickAction={disburseLoan}
                  />
                  <div className="mt-12">
                    <CheckBox
                      name="override"
                      text="Override previous disbursement"
                      checked={isOverrideDisbursementChecked}
                      setChecked={() =>
                        setIsOverrideDisbursementChecked(
                          !isOverrideDisbursementChecked
                        )
                      }
                    />
                    <p className="fs-14 fw-500 tart-orange">
                      Warning: this may lead to duplicate disbursements.
                      Double-check before enabling this option.
                    </p>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {canUpdateLoan && (
                <DelayedActionButton
                  className="btn outline"
                  text="Approve"
                  confirmText="Tap again to confirm"
                  busyText="Approving"
                  isBusy={state.submitting}
                  clickAction={approveLoan}
                />
              )}
            </>
          )}
        </div>
      );

    if (loan.closeRequest)
      return (
        <>
          {canUpdateCloseLoan && (
            <>
              <OutlineButton
                onClick={() => setRequestedFormID(CloseLoanFormID)}
                className="danger"
              >
                Decline Close Request
              </OutlineButton>
              <DelayedActionButton
                className="btn outline"
                text="Approve Close Request"
                confirmText="Tap again to confirm"
                busyText="Approving"
                isBusy={state.submitting}
                clickAction={approveCloseRequest}
              />
            </>
          )}
        </>
      );

    if (hasPendingStopInterestRequest)
      return (
        <>
          {canUpdateStopLoan && (
            <>
              <OutlineButton
                onClick={() => setRequestedFormID(StopLoanFormID)}
                className="danger"
              >
                Decline Stop Interest
              </OutlineButton>
              <DelayedActionButton
                className="btn outline"
                text="Approve Stop Interest"
                confirmText="Tap again to confirm"
                busyText="Approving"
                isBusy={state.submitting}
                clickAction={approveStopInterest}
              />
            </>
          )}
        </>
      );

    return (
      <div className="flex col gap">
        <div className="flex gap equal-widths">
          {canAddRepayment && (
            <OutlineButton
              onClick={() => setRequestedFormID(LoanRepaymentFormID)}
            >
              Add Loan Repayment
            </OutlineButton>
          )}
          {!isActive && canStopLoan && (
            <OutlineButton onClick={() => setRequestedFormID(StopLoanFormID)}>
              Stop Loan Interest
            </OutlineButton>
          )}
        </div>
        {canCloseLoan && !isCompleted && (
          <OutlineButton
            className="danger"
            onClick={() => setRequestedFormID(CloseLoanFormID)}
          >
            Close Loan
          </OutlineButton>
        )}
      </div>
    );
  };

  const actionButtons = createActionButtons();

  if (
    loanStatement &&
    loanStatement.data &&
    (loanStatement.statementDisplay || loanStatement.transactionDisplay) &&
    loanStatement.data.message?.includes("No statement found for loan")
  ) {
    return (
      <Modal handleClose={hideLoanStatement} className="message__modal">
        <div>
          <h1 className="fs-20">Loan Statement</h1>
          <p className="fs-16">{loanStatement.data.message}</p>
        </div>
        {<SuccessIcon />}
        <PrimaryButton className="fullwidth" onClick={hideLoanStatement}>
          Close
        </PrimaryButton>
      </Modal>
    );
  }

  if (loanStatement && loanStatement.data && loanStatement.statementDisplay) {
    return (
      <Modal fullScreen handleClose={hideLoanStatement}>
        <StatementAnalysis
          loan={loan}
          handleClose={hideLoanStatement}
          loanStatement={loanStatement.data}
        />
      </Modal>
    );
  }

  if (loanStatement && loanStatement.data && loanStatement.transactionDisplay) {
    return (
      <Modal fullScreen handleClose={hideLoanStatement}>
        <StatementTransactions
          handleClose={hideLoanStatement}
          loanStatement={loanStatement.data}
        />
      </Modal>
    );
  }

  if (sendMessageModal)
    return (
      <Modal
        rightSheet
        hideCancelButton
        className="account-holder-with-info-modal"
        handleClose={hideMessageModal}
      >
        <SendMessage
          accountName={loan.customerName}
          accountNumber={loan.accounts[0]?.accountNumber}
          customerID={loan.customerID}
          profileImageUrl={loan.profileImageUrl}
          handleClose={hideMessageModal}
        />
      </Modal>
    );

  if (disbursementInfoModal)
    return (
      <Modal
        rightSheet
        className="account-holder-with-info-modal"
        handleClose={hideDisbursementModal}
      >
        <DisbursementModal {...loan.disbursementInfo} />
      </Modal>
    );

  if (repaymentInfoModal)
    return (
      <Modal
        rightSheet
        className="account-holder-with-info-modal"
        handleClose={hideRepaymentModal}
      >
        <RepaymentModal repayments={loan.repayments} />
      </Modal>
    );

  return (
    <Modal
      rightSheet
      className="account-holder-with-info-modal"
      handleClose={dismissModal}
    >
      <AccountHolderBox
        accountName={loan.customerName}
        accountNumber={loan.accounts[0]?.accountNumber}
        profileImageUrl={loan.profileImageUrl}
        setSendMessageModal={setSendMessageModal}
        className="mb-20"
        infoList={requestedFormID === LoanRepaymentFormID ? [] : loanTypeInfo}
      >
        <h2 className="mb-16 fs-14">Loan History</h2>
        {Object.entries(loan.loanHistory ?? {}).map(([type, item], index) => (
          <React.Fragment key={index}>
            <h3 className="fs-16">
              {type}: {item.count}
            </h3>
            <p className="charcoal-60 fs-14 mb-16">
              Average Repayment Duration: {item.averageDuration} Days
            </p>
          </React.Fragment>
        ))}
      </AccountHolderBox>

      {requestedFormID === LoanRepaymentFormID && (
        <LoanRepaymentForm
          cancel={() => setRequestedFormID(null)}
          loan={loan}
          mutate={mutate}
          activeFilter={activeFilter}
          dismissModal={dismissModal}
          canAddRepayment={canAddRepayment}
        />
      )}

      {requestedFormID === StopLoanFormID && (
        <ActionForm
          cancel={() => setRequestedFormID(null)}
          loan={loan}
          mutate={mutate}
          dismissModal={dismissModal}
          url={
            isFewchoreAdmin && partnerID
              ? `/loan/stop-interest?partnerID=${partnerID ?? ""}`
              : `/loan/stop-interest`
          }
          title={
            loan.stopInterestRequest
              ? "Reject Stop Loan Interest"
              : "Stop Loan Interest"
          }
          hasPending={loan.stopInterestRequest}
        />
      )}

      {requestedFormID === CloseLoanFormID && (
        <ActionForm
          cancel={() => setRequestedFormID(null)}
          loan={loan}
          mutate={mutate}
          dismissModal={dismissModal}
          url={
            isFewchoreAdmin && partnerID
              ? `/loan/close?partnerID=${partnerID ?? ""}`
              : `/loan/close`
          }
          title={loan.closeRequest ? "Reject Close Loan" : "Close Loan"}
          hasPending={loan.closeRequest}
        />
      )}

      {requestedFormID === DeclineLoanFormID && (
        <ActionForm
          cancel={() => setRequestedFormID(null)}
          loan={loan}
          mutate={mutate}
          activeFilter={activeFilter}
          dismissModal={dismissModal}
          url={
            isFewchoreAdmin && partnerID
              ? `/loan/update?partnerID=${partnerID ?? ""}`
              : `/loan/update`
          }
          title="Decline Loan"
          isDecline
        />
      )}

      {!requestedFormID && loanInfoElem && (
        <div className="mb-20">{loanInfoElem}</div>
      )}

      {!requestedFormID && repaymentSchedule && repaymentSchedule.length ? (
        <LoanRepaymentScheduleDisplay
          amountPaid={amountPaid}
          repaymentSchedule={repaymentSchedule}
        />
      ) : null}

      {!requestedFormID && guarantorInfo && (
        <GuarantorInfoDisplay guarantorInfo={guarantorInfo} />
      )}

      {!requestedFormID && showCards && (
        <div className="mb-20">{showCards}</div>
      )}

      {isAssetFinancingLoan && (
        <LoanOffers loanFormData={loanFormData} setData={setData} />
      )}

      {!requestedFormID && adminInfoElem && (
        <div className="mb-20">{adminInfoElem}</div>
      )}

      {!requestedFormID && actionButtons && (
        <div className="flex gap equal-widths fs-16">{actionButtons}</div>
      )}
    </Modal>
  );
}

export default LoanDetailsModal;
