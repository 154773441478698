import { useState } from "react";
import { useOutletContext } from "react-router";
import { BiCaretDown } from "react-icons/bi";

import { CustomerInfoForm, ChangeAccountStatusForm } from "../reset-pin/forms";
import useToggle from "../../../hooks/useToggle.hook";
import { FALLBACK_IMAGE_URL } from "../../../config/swr";

const CustomerInfoFormID = 1,
  ChangeAccountStatusFormID = 2;

export default function ChangeAccountStatusPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Change Account Status");
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const [pageState, setPageState] = useState({ formID: CustomerInfoFormID });
  const [fetchingData, setFetchingData] = useState(false);
  const changeForm = (formID, data) => setPageState({ formID, data });
  const backToCustInfo = () => changeForm(CustomerInfoFormID);

  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };

  const data = pageState.data;

  const dataDisplayClass = fetchingData
    ? "data-display fetching"
    : data
    ? "data-display"
    : "data-display empty-blocks";

  const customerInfo = data ? [] : Array(2).fill({});

  const custDetails = data
    ? [
        { title: "Email Address", value: data.customerInfo?.email },
        {
          title: "Phone number",
          value: data.customerInfo?.phoneNumber,
        },
        { title: "BVN", value: data.customerInfo?.bvn },
        { title: "Date of Birth", value: data.customerInfo?.dateOfBirth },
      ]
    : Array(6).fill({});

  return (
    <div className="set-pin-content">
      <div className="box form-container">
        {pageState.formID === CustomerInfoFormID && (
          <CustomerInfoForm
            setFetchingData={setFetchingData}
            onSuccess={(data) => changeForm(ChangeAccountStatusFormID, data)}
          />
        )}

        {pageState.formID === ChangeAccountStatusFormID && (
          <ChangeAccountStatusForm
            data={pageState.data}
            onClose={backToCustInfo}
          />
        )}
      </div>

      <div className={`box ${dataDisplayClass}`}>
        <div className="flex center">
          <ImageComp
            profileImageUrl={data?.customerInfo?.profileImageURL}
            key={data?.accountNumber}
          />
        </div>

        {data?.customerInfo && (
          <div className={`flex col ${data ? "gap-8" : "gap-4"} mb-20 center`}>
            <div className="flex col center">
              <h3 className="fs-20 mb-6">{data?.customerInfo?.fullName}</h3>
              <p className={`charcoal-60`}>
                {data?.customerInfo?.accounts?.length} accounts
              </p>
            </div>
          </div>
        )}

        <div className={`flex col ${data ? "gap-8" : "gap-4"} mb-20`}>
          {customerInfo.map((info, index) => (
            <div key={index} className="info-row flex mb-4 center">
              <div>{info?.title}</div>
            </div>
          ))}
        </div>

        <hr className="mb-20" />

        <div className="flex col">
          {custDetails.map((info, index) => (
            <div key={index} className="info-row flex mb-4">
              <div>{info?.title}</div>
              <div>{info?.value}</div>
            </div>
          ))}
          {data?.customerInfo?.accounts &&
            data?.customerInfo?.accounts?.length > 0 && (
              <div className="card__box mb-12">
                <div
                  className="flex gap between-center"
                  onClick={toggleDropdown}
                >
                  <span>Accounts</span>
                  <span>
                    <BiCaretDown
                      size={18}
                      className={
                        dropdownOpened
                          ? "dropdown-icon rotate-180"
                          : "dropdown-icon"
                      }
                    />
                  </span>
                </div>
                <div
                  className={
                    dropdownOpened
                      ? "message__dropdown opened"
                      : "message__dropdown"
                  }
                >
                  <div className="card__grid">
                    {data?.customerInfo?.accounts?.map(
                      ({ accountNumber, status }, i) => (
                        <div key={i} className="flex gap-8 between-center">
                          <span className="charcoal-60">
                            {`Account Number ${i + 1}`}:
                          </span>
                          <h3>
                            {`${accountNumber} `}
                            <span className="charcoal-60">({status})</span>
                          </h3>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

const ImageComp = ({ profileImageUrl }) => {
  const [imgSrc, setImgSrc] = useState(profileImageUrl ?? FALLBACK_IMAGE_URL);

  return (
    <img
      src={imgSrc}
      onError={() => {
        setImgSrc(FALLBACK_IMAGE_URL);
      }}
      alt="customer dp"
      className={`profile-img mb-12`}
    />
  );
};
