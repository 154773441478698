import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { BackButton, PrimaryButton } from "../../../components/Button";
import AdminPasswordForm from "../../admin-users/forms/AdminPasswordForm";
import Modal from "../../../components/Modal";

import { sendGetRequest, sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import useFCMToken from "../../../hooks/useFCMToken.hook";

export function CustomerInfoForm({ onSuccess, setFetchingData }) {
  const { trigger } = useSWRMutation(`/customer-info?`, sendGetRequest);

  const form = useForm({
    accountNumber: "",
  });

  const showMessageModal = useMessageModal();

  const submitCustomerInfoForm = async (e) => {
    e.preventDefault();
    const { accountNumber } = form.data;
    setFetchingData(true);
    form.submitStarted();

    try {
      const res = await trigger(`loginID=${accountNumber}`);
      form.submitSuccess();
      onSuccess({ customerInfo: res.data, ...form.data });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.response?.data ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
    setFetchingData(false);
  };

  return (
    <>
      <b className="fs-24 mb-20">Enter Customer Detail</b>
      <form onSubmit={submitCustomerInfoForm}>
        <div className="mb-12">
          <label>Account Number/Phone No/BVN</label>
          <input
            placeholder="002xxxxxxxxxxxx"
            {...form.fieldProps("accountNumber")}
          />
        </div>

        <PrimaryButton
          className="fullwidth"
          disabled={!form.data.accountNumber || form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Search"}
        </PrimaryButton>
      </form>
    </>
  );
}

export function ResetPinForm({ onClose, data }) {
  const [showModal, setShowModal] = useState(false);
  const [newPin, setNewPin] = useState("");
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const form = useForm();

  return (
    <>
      <BackButton onClick={onClose} className="mb-16" />

      <div>
        {newPin && (
          <div className="gray-box flex col gap-8 mb-16">
            <div>
              <p>New Transaction Pin</p>
              <b>{newPin}</b>
            </div>
          </div>
        )}

        <b className="fs-24 mb-12">Reset Pin</b>

        <div className="mb-20">
          <label>Account Number/Phone No/BVN</label>
          <input value={data.accountNumber} readOnly />
        </div>

        <div>
          <PrimaryButton
            className="fullwidth"
            disabled={form.state.submitting || newPin}
            onClick={openModal}
          >
            {form.state.submitting ? <FiLoader /> : "Reset Transaction Pin"}
          </PrimaryButton>
        </div>

        {showModal && (
          <ResetPinModal
            done={closeModal}
            setNewPin={setNewPin}
            customerID={data.customerInfo?.customerID}
          />
        )}
      </div>
    </>
  );
}

function ResetPinModal({ customerID, done, setNewPin }) {
  const fcmToken = useFCMToken();
  const showMessageModal = useMessageModal();
  const { trigger: resetTrigger } = useSWRMutation(
    `/temporary-customer-txpin`,
    sendPostRequest
  );

  const { trigger: accessTrigger } = useSWRMutation(
    `/admin/request-secure-access`,
    sendPostRequest
  );

  const toggleAdminStatus = async (password, form) => {
    const notificationToken = fcmToken.value();

    const deviceID = notificationToken;

    try {
      const res = await accessTrigger({
        password,
        deviceID,
      });

      const resp = await resetTrigger({
        customerLoginID: customerID,
        secureAccessToken: res.data.secureAccessToken,
      });
      done();
      setNewPin(resp.data.message);
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.response?.data ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <Modal
      handleClose={() => done()}
      className="modal-heading-subheading-buttons"
    >
      <div className="mb-32">
        <h1 className="fs-20">Reset Pin</h1>
        <p>Enter your password to reset pin</p>
      </div>

      <div className="mb-6">
        <AdminPasswordForm
          buttonClass="fullwidth btn primary"
          processPassword={toggleAdminStatus}
        />
      </div>

      <button className="fullwidth btn" onClick={() => done()}>
        Cancel
      </button>
    </Modal>
  );
}

export function ChangeAccountStatusForm({ onClose, data }) {
  const [showModal, setShowModal] = useState(false);
  const [secureAccessToken, setSecureAccessToken] = useState(null);

  const { trigger: changeStatusTrigger } = useSWRMutation(
    `/customer-account-status`,
    sendPostRequest
  );

  const { trigger: accessTrigger } = useSWRMutation(
    `/admin/request-secure-access`,
    sendPostRequest
  );

  const form = useForm({
    suspendAccount: false,
    reason: "",
  });

  const showMessageModal = useMessageModal();

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const submitChangeStatusForm = async (e) => {
    e.preventDefault();

    if (!secureAccessToken) {
      openModal(); // Open modal if secure token isn't available
      return;
    }

    form.submitStarted(); // Mark form as submitting

    try {
      await changeStatusTrigger({
        customerLoginID: data.customerInfo?.customerID, // Ensure customer ID is included
        suspendAccount: form.data.suspendAccount === "true", // Convert string to boolean
        secureAccessToken, // Pass the secure access token
        reason: form.data.reason,
      });

      showMessageModal({
        title: "Success",
        message: "Account status has been updated successfully.",
        isError: false,
        closeButtonText: "Close",
      });

      form.submitSuccess(); // Mark form as successful
      onClose(); // Close the form
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.response?.data ||
          "An error occurred. Please try again.",
        isError: true,
        closeButtonText: "Close",
      });

      form.submitError(); // Mark form as errored
    }
  };

  const requestSecureAccess = async (password) => {
    try {
      const res = await accessTrigger({ password });
      setSecureAccessToken(res.data.secureAccessToken);
      closeModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.response?.data ||
          "Unable to get secure access token. Please try again.",
        isError: true,
        closeButtonText: "Close",
      });
    }
  };

  return (
    <>
      <BackButton onClick={onClose} className="mb-16" />

      <b className="fs-24 mb-12">Change Account Status</b>

      <form onSubmit={submitChangeStatusForm}>
        <div className="mb-12">
          <label>Account Number/Phone No/BVN</label>
          <input value={data.accountNumber} readOnly />
        </div>

        <div className="mb-12">
          <label>Status</label>
          <select
            {...form.fieldProps("suspendAccount")}
            defaultValue={form.data.suspendAccount}
          >
            <option value={"false"}>Active</option>
            <option value={"true"}>Suspended</option>
          </select>
        </div>

        <div className="mb-12">
          <label>Reason</label>
          <input
            id="reason"
            {...form.fieldProps("reason")}
            defaultValue={form.data.reason}
            placeholder="Provide a reason for the change"
          />
        </div>

        <PrimaryButton
          className="fullwidth"
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Change Status"}
        </PrimaryButton>
      </form>

      {showModal && (
        <Modal
          handleClose={closeModal}
          className="modal-heading-subheading-buttons"
        >
          <div className="mb-32">
            <h1 className="fs-20">Secure Access</h1>
            <p>Enter your password to request secure access token</p>
          </div>

          <div className="mb-6">
            <AdminPasswordForm
              buttonClass="fullwidth btn primary"
              processPassword={requestSecureAccess}
            />
          </div>

          <button className="fullwidth btn" onClick={closeModal}>
            Cancel
          </button>
        </Modal>
      )}
    </>
  );
}
