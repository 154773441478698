import React from "react";
import { NavLink } from "react-router-dom";
import {
  RiDashboardFill,
  RiFileList2Fill,
  RiSettings5Fill,
  RiAlarmFill,
} from "react-icons/ri";
import { MdOutlineAppSettingsAlt, MdAssignmentInd } from "react-icons/md";
import { BsCollectionFill, BsGlobe } from "react-icons/bs";
import { FaUserCircle, FaMoneyBillWave, FaMoneyCheckAlt } from "react-icons/fa";
import { BiCaretDown } from "react-icons/bi";

import useToggle from "../../hooks/useToggle.hook";
import { getPermissions } from "../../config/swr";
import { PayrollPermissions } from "../../pages/payroll";

const VIEW_SUMMARY_KEY = "dashboard-summary";

const linkClass = ({ isActive }) => {
  return isActive ? "sidebar__link active" : "sidebar__link";
};

const LinkWithoutSubs = ({ path, link }) => {
  return (
    <NavLink to={path} className={linkClass}>
      <link.icon size={24} />
      <span>{link.displayText}</span>
    </NavLink>
  );
};

const LinkWithSubLinks = ({ path, link, allowedSublinks }) => {
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };

  const dropdownOpenedClass =
    link.subLinks.length > 4
      ? "sidebar__dropdown opened400"
      : "sidebar__dropdown opened200";

  if (allowedSublinks.length === 0) return null;

  return (
    <>
      <NavLink
        to={path}
        className={linkClass}
        style={{ marginBottom: 0 }}
        onClick={toggleDropdown}
      >
        <link.icon size={24} />
        <span>{link.displayText}</span>
        <BiCaretDown
          size={18}
          className={
            dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
          }
        />
      </NavLink>

      <ul
        className={dropdownOpened ? dropdownOpenedClass : "sidebar__dropdown"}
      >
        {allowedSublinks.map((subLink) => {
          const subLinkPath =
            path + "/" + subLink.toLowerCase().replace(/ /g, "-");
          return (
            <li key={subLinkPath}>
              <NavLink to={subLinkPath} className={linkClass}>
                {subLink}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const makeLink = (displayText, icon, permissionsKey, subLinks) => {
  return { displayText, icon, permissionsKey, subLinks: subLinks ?? [] };
};

export const RenderSideBarLinks = () => {
  const permissions = getPermissions();

  const sideBarLinks = permissions[VIEW_SUMMARY_KEY]
    ? [
        makeLink("Overview", RiDashboardFill, "dashboard-summary"),
        makeLink("Transactions", RiFileList2Fill, "fix-payment-issues"),
        makeLink("Mobile Ads", MdOutlineAppSettingsAlt, "view-mobile-ads"),
        makeLink("Internet Banking Ads", BsGlobe, ""),
        makeLink("Promos", RiAlarmFill, "", [
          {
            subLink: "Referral Promos",
            permissionsKey: "view-referral-promos",
          },
        ]),
        makeLink("Loans", FaMoneyBillWave, "view-loans", [
          {
            subLink: "Payday Loans",
            permissionsKey: "dashboard-summary",
          },
          {
            subLink: "Retail Loans",
            permissionsKey: "dashboard-summary",
          },
          {
            subLink: "Asset Financing",
            permissionsKey: "dashboard-summary",
          },
          {
            subLink: "Loan Partners",
            permissionsKey: "view-loan-partners",
          },
        ]),
        makeLink("Collections", BsCollectionFill, "view-collections"),
        makeLink("Customers", FaUserCircle, "", [
          {
            subLink: "Accounts CSV",
            permissionsKey: "export-accounts",
          },
          {
            subLink: "Account Upgrade",
            permissionsKey: "view-account-upgrade-requests",
          },
          {
            subLink: "Address Update",
            permissionsKey: "view-address-update-requests",
          },
          {
            subLink: "Batch Account",
            permissionsKey: "view-batch-accounts",
          },
          {
            subLink: "Cards",
            permissionsKey: "view-card-requests",
          },
          {
            subLink: "Reset PIN",
            permissionsKey: "set-temporary-transaction-pin",
          },
          {
            subLink: "Change Account Status",
            permissionsKey: "set-temporary-transaction-pin",
          },
          {
            subLink: "Validate Customer Info",
            permissionsKey: "",
          },
          {
            subLink: "Virtual Accounts",
            permissionsKey: "view-virtual-accounts",
          },
        ]),
        makeLink("Payroll", FaMoneyCheckAlt, "", [
          {
            subLink: "Staff",
            permissionsKey: PayrollPermissions.VIEW_PAYROLL_STAFF_PERM,
          },
          {
            subLink: "Upcoming Payments",
            permissionsKey:
              PayrollPermissions.VIEW_PAYROLL_UPCOMING_PAYMENT_PERM,
          },
          {
            subLink: "History",
            permissionsKey: PayrollPermissions.VIEW_PAYROLL_HISTORY_PERM,
          },
          {
            subLink: "Settings",
            permissionsKey: PayrollPermissions.VIEW_PAYROLL_SETTINGS_PERM,
          },
        ]),
        makeLink("Admin Users", MdAssignmentInd, "view-admins"),
        makeLink("Settings", RiSettings5Fill, "", [
          {
            subLink: "General",
            permissionsKey: "view-general-settings",
          },
          {
            subLink: "Loans",
            permissionsKey: "",
            permissionsKeys: [
              "view-general-loan-settings",
              "view-payday-loan-settings",
              "view-retail-loan-settings",
            ],
          },
          {
            subLink: "Collections",
            permissionsKey: "",
            permissionsKeys: [
              "view-general-collection-settings",
              "view-target-collection-settings",
            ],
          },
          {
            subLink: "Investments",
            permissionsKey: "view-investments-settings",
          },
          {
            subLink: "Cards",
            permissionsKey: "view-card-requests-settings",
          },
          {
            subLink: "Security Roles",
            permissionsKey: "view-security-roles",
          },
          {
            subLink: "Security",
            permissionsKey: "",
          },
        ]),
      ]
    : [makeLink("Loans", FaMoneyBillWave, "view-loans")];

  return sideBarLinks.map((link) => {
    const path = "/" + link.displayText.toLowerCase().replace(/ /g, "-");
    const allowedSublinks = [];

    if (link.permissionsKey === "" || permissions[link.permissionsKey]) {
      if (link.subLinks.length === 0) {
        return <LinkWithoutSubs key={path} path={path} link={link} />;
      }

      link.subLinks.forEach(({ subLink, permissionsKey, permissionsKeys }) => {
        if (permissions[permissionsKey]) return allowedSublinks.push(subLink);

        if (
          permissionsKey === "" &&
          permissionsKeys &&
          permissionsKeys.some((key) => permissions[key])
        ) {
          return allowedSublinks.push(subLink);
        }

        if (permissionsKey === "" && !permissionsKeys) {
          return allowedSublinks.push(subLink);
        }
      });

      return (
        <LinkWithSubLinks
          key={path}
          path={path}
          link={link}
          allowedSublinks={allowedSublinks}
        />
      );
    }

    return null;
  });
};
