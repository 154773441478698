import { useState } from "react";
import { SWRConfig } from "swr";
import { useNavigate } from "react-router";
import axios from "axios";

import Modal from "../components/Modal";
import { PrimaryButton } from "../components/Button";
import ErrorIcon from "../assets/icons/erroricon";

export const FALLBACK_IMAGE_URL =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png";

// export const BASE_URL = "admin-staging.fewchoremobile.com/v2";
export const BASE_URL = "admin-api.fewchoremobile.com/v2";

export function getToken() {
  return window.localStorage.getItem("token");
}

export function setToken(token) {
  window.localStorage.setItem("token", token);
}

export function clearToken() {
  window.localStorage.removeItem("token");
}

export function getPermissions() {
  return JSON.parse(window.localStorage.getItem("permissions")) || {};
}

export function setPermissions(permissions) {
  window.localStorage.setItem("permissions", JSON.stringify(permissions));
}

export function clearPermissions() {
  window.localStorage.removeItem("permissions");
}

export function useLogout() {
  const navigate = useNavigate();
  return () => {
    clearToken();
    clearPermissions();
    navigate("/login");
  };
}

const axiosInstance = axios.create({
  baseURL: "https://" + BASE_URL,
});

export async function sendPostRequest(subPath, { arg }) {
  return axiosInstance.post(subPath, arg);
}

export async function sendPutRequest(subPath, { arg }) {
  const { subUrl, payload } = arg;
  return axiosInstance.put(`${subPath}${subUrl ?? ""}`, payload);
}

export async function sendPatchRequest(subPath, { arg }) {
  const { subUrl, payload } = arg;
  return axiosInstance.patch(`${subPath}${subUrl ?? ""}`, payload);
}

export async function sendDeleteRequest(subPath, { arg }) {
  return axiosInstance.delete(`${subPath}${arg}`);
}

export async function sendGetRequest(subPath, { arg }) {
  return axiosInstance.get(`${subPath}${arg}`);
}

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((res) => {
  const updatedToken = res.headers.get("authToken");
  if (updatedToken) setToken(updatedToken);
  return res;
});

const CustomSWR = ({ children }) => {
  const logout = useLogout();
  const [errorText, setErrorText] = useState("");

  const openLogoutModal = (text) => setErrorText(text);
  const closeLogoutModal = () => setErrorText("");

  const handleLogout = () => {
    logout();
    closeLogoutModal();
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      if (error?.response?.data?.errorMessage.includes("please login again"))
        return openLogoutModal(
          <>
            Please login again <br />
            One or more security setting has been updated
          </>
        );

      if (
        error?.response?.data?.errorMessage.includes(
          "invalid token in authorization header"
        )
      )
        return openLogoutModal(
          <>
            Your session has expired. <br /> Kindly login again
          </>
        );
    } else {
      console.log(error);
    }
  };

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        onError: (error) => handleError(error),
        fetcher: (url) => axiosInstance.get(url).then((res) => res.data),
      }}
    >
      {children}
      {errorText && (
        <Modal handleClose={closeLogoutModal} className="message__modal">
          <div>
            <h1 className="fs-20">Error</h1>
            <p className="fs-16">{errorText}</p>
          </div>
          {<ErrorIcon />}
          <PrimaryButton
            className="fullwidth"
            style={{ backgroundColor: "#9B0000" }}
            onClick={handleLogout}
          >
            Proceed to Login
          </PrimaryButton>
        </Modal>
      )}
    </SWRConfig>
  );
};

export default CustomSWR;
